import React from "react"
import Layout from "../../components/layout"

const ServicePage = () => {
    return (
        <Layout>
            <div className="mx-auto container px-4">
                <h1>Signal Integrity</h1>
                <hr className="mb-4" />
                <p>Multi-Gigabit Channel SI characterization including</p>
                <ul>
                    <li>Eye diagram generation and evaluation</li>
                    <li>S-Parameter model generation</li>
                    <li>Bit Error Rate Prediction/optimization</li>
                    <li>Optimization of FPGA SERDES buffer selection</li>
                </ul>
                <p>High Speed / DDRX Memory bus analysis</p>
                <ul>
                    <li>Edge quality / jitter analysis</li>
                    <li>Interconnect Delay reports</li>
                    <li>Detailed Timing Margin Analysis report</li>
                </ul>
                <p>
                    Identify and remediate high speed reliability hazards such
                    as
                </p>
                <ul>
                    <li>
                        Comprehensive Crosstalk simulations from both expected
                        and unexpected sources
                    </li>
                    <li>Over/Under voltage component hazard reports</li>
                    <li>Drive Strength / Noise problems</li>
                    <li>Clock Signal edge glitches</li>
                    <li>Manufacturing tolerance effects on Signal quality</li>
                </ul>
                <p>Optimize and Validate high speed trace routing</p>
                <ul>
                    <li>Effects of Trace branch points and topology</li>
                    <li>Termination component selection and placement</li>
                    <li>Develop routing rules to avoid crosstalk</li>
                    <li>Connector selection and evaluation</li>
                    <li>Board Stack up impedance analysis</li>
                </ul>
            </div>
        </Layout>
    )
}

export default ServicePage
